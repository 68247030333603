* {
  box-sizing: border-box;
}

html {
  --bg: #efefef;
  --fg: #1e1e1e;
  --gray: #555;
  --light-gray: #ccc;
  --shadow: #e6e6e6;
  --success: rgb(90, 206, 132);
  --secondary: #0072CE;
  --secondary-bg: #0072CE;
  --header-font-color: #efefef;

  --header-primary-color: #FFF;
  --header-secondary-color: #555;
  --header-nav-height: 50px;
  --footer-height: 34px;
  --nav-background-color: rgb(19, 47, 76);

  background-color: var(--bg);
  color: var(--fg);
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif;
  font-size: calc(0.9em + 0.5vw);
  line-height: 1.3;
}

body {
  margin: 0;
  padding: 0;
  font-family: BwSeidoRound, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
}

.background-img {
  position: absolute;
  right: 0;
  z-index: -1;
  background-image: url(/assets/explorer-bg.svg);
  width: 504px;
  height: 324px;
}

@font-face {
  font-family: BwSeidoRound;
  src: url(./fonts/389947_6_0.eot);
  src: url(./fonts/389947_6_0.eot?#iefix) format("embedded-opentype"), url(./fonts/389947_6_0.woff2) format("woff2"), url(./fonts/389947_6_0.woff) format("woff"), url(./fonts/389947_6_0.ttf) format("truetype");
  font-weight: 500;
  font-style: normal
}


main {
  margin: 0 auto;
  max-width: 25em;
  padding-top: var(--header-nav-height);
  padding-bottom: var(--footer-height);
}

h1 {
  background-image: url(assets/logo.svg);
  background-position: center 1em;
  background-repeat: no-repeat;
  background-size: auto 1.5em;
  margin-top: 0;
  padding: 3.5em 0 0.5em;
  text-align: center;
}

a,
.link {
  text-decoration: none;
}

a:hover,
a:focus,
.link:hover,
.link:focus {
  text-decoration: underline;
}

a:active,
.link:active {
  color: var(--secondary);
}

button, input {
  font: inherit;
  outline: none;
}

button {
  background-color: var(--secondary);
  border-radius: 5px;
  border: none;
  color: #efefef;
  cursor: pointer;
  padding: 0.3em 0.75em;
  transition: transform 30ms;
}

button:hover, button:focus {
  box-shadow: 0 0 10em rgba(255, 255, 255, 0.2) inset;
}

button:active {
  box-shadow: 0 0 10em rgba(0, 0, 0, 0.1) inset;
}

button.link {
  background: none;
  border: none;
  box-shadow: none;
  display: inline;
}

[disabled] button, button[disabled] {
  box-shadow: none;
  background-color: var(--light-gray);
  color: gray;
  cursor: not-allowed;
  transform: none;
}

[disabled] button {
  text-indent: -900em;
  width: 2em;
  position: relative;
}

[disabled] button:after {
  content: " ";
  display: block;
  width: 0.8em;
  height: 0.8em;
  border-radius: 50%;
  border: 2px solid var(--fg) transparent var(--fg) transparent;
  animation: loader 1.2s linear infinite;
  position: absolute;
  top: 0.45em;
  right: 0.5em;
}

@keyframes loader {
  0% {
    transform: rotate(0deg)
  }
  100% {
    transform: rotate(360deg)
  }
}

fieldset {
  border: none;
  padding: 0.1em 0 0 0;
}

input {
  background-color: var(--shadow);
  border: none;
  color: inherit;
  padding: 0.25em 1em;
}

input::selection {
  background-color: var(--secondary);
  color: #efefef;
}

input:focus {
  box-shadow: 0 0 10em rgba(0, 0, 0, 0.02) inset;
}

code {
  color: var(--gray);
}

li {
  padding-bottom: 1em;
}

aside {
  animation: notify ease-in-out 10s;
  background-color: var(--shadow);
  border-radius: 5px;
  bottom: 0;
  font-size: 0.8em;
  margin: 1em;
  padding: 1em;
  position: fixed;
  transform: translateY(10em);
  right: 0;
}

aside footer {
  display: flex;
  font-size: 0.9em;
  justify-content: space-between;
  margin-top: 0.5em;
}

aside footer *:first-child {
  color: var(--success);
}

aside footer *:last-child {
  color: var(--gray);
}

@keyframes notify {
  0% {
    transform: translateY(10em)
  }
  5% {
    transform: translateY(0)
  }
  95% {
    transform: translateY(0)
  }
  100% {
    transform: translateY(10em)
  }
}

@media (prefers-color-scheme: dark) {
  html {
    --bg: #1e1e1e;
    --fg: #efefef;
    --gray: #aaa;
    --shadow: #2a2a2a;
    --light-gray: #444;
  }

  h1 {
    background-image: url(assets/logo-white.svg);
  }

  input:focus {
    box-shadow: 0 0 10em rgba(255, 255, 255, 0.02) inset;
  }
}


.send-button, .deposit-button, .deposit-send-button {
  border-radius: 5px;
  margin-right: 1em;
}

.action-buttons {
  padding-top: 0.2em;
  font-size: 0.8em
}

.hidden {
  display: none;
}

/* FOOTER */

.footer {
  width: 100%;
  background-color: var(--light-gray);
  color: var(--fg);
  height: 35px;
  position: fixed;
  left: 0;
  bottom: 0;
  display: flex;
}

.footer a {
  color: var(--fg)
}

.footer div {
  display: inline-block;
  font-size: 0.8em;
  padding-bottom: 0.3em;
}

.footer .build-on-near {
  cursor: pointer;
  padding: 0.2em 0.3em 0.1em 0.3em;
  margin: 0 0.3em 0 0.75em;
  background-color: var(--nav-background-color);
  height: var(--footer-height);
}

.footer .build-on-near a {
  color: white
}

.footer .github {
  padding-left: 5px;
}

.footer .github {
  display: flex;
  max-width: 50%;
  width: 50%;
}

.footer .promo, .footer .github {
  margin-top: 5px;
}

.footer .promo {
  text-align: right;
  flex: 1;
}

.footer .github .brand {
  padding-top: 3px;
}

/* HEADER */

.nav-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.nav-header {
  color: white;
  position: relative;
  font-size: 0.8em;
  margin-bottom: 20px;
  padding-left: 15px;
  box-shadow: rgba(0, 0, 0, 0.17) 0px 5px 9px -1px;
  background-color: var(--nav-background-color);
  height: var(--header-nav-height);
  -webkit-box-align: center;
  align-items: center;
  display: flex;
  cursor: default;
}

.nav-header .user-name {
  white-space: nowrap;
  margin-left: 5px;
}

.nav-header .user-balance {
  margin-left: 10px;
  white-space: nowrap;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  background-color: black;
  padding: 2px 10px;
  border-radius: 40px;
  font-size: 14px;
  color: rgb(143, 214, 189);
}

.nav-header .nav-item {
  display: flex;
}

.nav-header .near-logo {
  width: 100px;
  height: 50px;
  fill: var(--header-primary-color);
}

.nav-header .logo-container {
  border-right: 2px solid rgb(93, 95, 96);
  color: var(--header-primary-color);
  align-items: center;
  justify-content: center;
  display: flex;
}

.nav-header .logo-container .app-name {
  padding-right: 10px;
  color: var(--header-primary-color);
}

.nav-header .align-right {
  display: flex;
  margin-left: auto;
  order: 2;
  align-items: center;
  justify-content: center;
}

.footer .promo {
  padding-right: 5px;
  float: right;
  vertical-align: top;
  height: 100%;
}

/* NAV MENU */

.nav-menu .network-icon {
  background-image: url(/assets/icon-network-right.svg);
  border-radius: 0;
  width: 15px;
  height: 15px;
}

.nav-menu .network-title {
  text-transform: capitalize;
  color: var(--header-secondary-color);
}

.menu-container {
  position: relative;
}

.menu {
  background: #ffffff;
  border-radius: 8px;
  position: absolute;
  top: 30px;
  right: 0;
  width: 95px;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
  opacity: 0;
  visibility: hidden;
  transform: translateY(-20px);
  transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;
}

.menu.active {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.menu li {
  padding-left: 10px;
  border-bottom: 1px solid #dddddd;
  padding-bottom: 0;
}

.menu li a {
  text-decoration: none;
  color: #333333;
  padding: 5px;
  display: block;
  font-size: 0.6em;
}

.menu-trigger {
  background: #ffffff;
  border-radius: 90px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 6px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  border: none;
  vertical-align: middle;
  transition: box-shadow 0.4s ease;
}

.menu-trigger:hover {
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
}

.menu-trigger span {
  font-weight: 700;
  vertical-align: middle;
  font-size: 14px;
  margin: 0 10px;
}

.menu-trigger img {
  border-radius: 90px;
}

/* MOBILE */

.content-desktop {
  display: block;
}

.content-mobile {
  display: none;
}

@media screen and (max-width: 768px) {
  .content-desktop {
    display: none !important;
  }

  .content-mobile {
    display: block !important;
  }
}